.app {
  background-image: url("./assest/backgroundPhoto.jpg");
  background-size: cover;
  min-height: 100vh;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  background-size: cover;
}

.search {
  width: 80%;
  outline: none;
  border: none;
  height: 2.3em;
  font-size: 1.3em;
}